import React, { useEffect } from "react";
import "../styles/home.scss";
import { Projects } from "../components/Projects";
import { ContactButton } from "../nav-elements/ContactButton";
import { NotoVictoryHand } from "../components/emoji/peace";
import { WaveSVG } from "../components/WaveSvg";
import { CurveSep } from "../components/CurveSep";
import { MountainTransition } from "../components/MountainTransition";

export const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container">
      <ContactButton />
      <div className="dots" />
      <div className="home">
        <div className="main-title">
          <div className="width-restrict">
            <div className="heading">
              <p className="location-text">📍 Austria</p>
              <h1>
                Hi,
                <NotoVictoryHand />
              </h1>
              <h1>I'm Elias</h1>
              <p>React / Angular Frontend Dev</p>
            </div>
          </div>
          <WaveSVG />
        </div>
        <Projects />
        <svg
          preserveAspectRatio="xMidYMax meet"
          className="svg-separator sep6"
          viewBox="0 0 1600 200"
          style={{ display: "block" }}
        >
          <polygon
            style={{ fill: "rgb(91 100 110)" }}
            points="-4,188.586 174,76 292,124 402,60 536,108 752,8 882,66 990,32 1210,116 1380,64 1536,150 1604,115.09 1604,204 -4,204 "
          ></polygon>
          <polygon
            style={{ opacity: 1, fill: "#95a5a6" }}
            points="174,76 174,78 -4,198 -4,188.586 "
          ></polygon>
          <polygon
            style={{ opacity: 1, fill: "#95a5a6" }}
            points="292,124 302,138 402,64 402,60 "
          ></polygon>
          <polygon
            style={{ opacity: 1, fill: "#95a5a6" }}
            points="536,108 544,120 752,12 752,8 "
          ></polygon>
          <polygon
            style={{ opacity: 1, fill: "#95a5a6" }}
            points="882,66 890,78 990,36 990,32 "
          ></polygon>
          <polygon
            style={{ opacity: 1, fill: "#95a5a6" }}
            points="1210,116 1222,130 1380,68 1380,64 "
          ></polygon>
          <polygon
            style={{ opacity: 1, fill: "#95a5a6" }}
            points="1536,150 1546,164 1604,124 1604,115.09 "
          ></polygon>
          <polygon
            style={{ opacity: 1, fill: "#34495e" }}
            points="174,76 292,124 282,140 174,78 "
          ></polygon>
          <polygon
            style={{ opacity: 1, fill: "#34495e" }}
            points="402,60 536,108 528,120 402,64 "
          ></polygon>
          <polygon
            style={{ opacity: 1, fill: "#34495e" }}
            points="752,8 882,66 874,80 752,12 "
          ></polygon>
          <polygon
            style={{ opacity: 1, fill: "#34495e" }}
            points="990,32 990,36 1192,130 1210,116 "
          ></polygon>
          <polygon
            style={{ opacity: 1, fill: "#34495e" }}
            points="1380,64 1536,150 1526,168 1380,68 "
          ></polygon>
        </svg>
      </div>
    </div>
  );
};
